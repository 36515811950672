<template>
  <div>
    <!--  签约订单明细  -->
    <el-dialog
      title="服务订单明细"
      :visible.sync="isOpenModal"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      width="1280px"
      @closed="closeModal">
      <div class="dialog-body-wrapper">
        <!-- 应用订单明细 -->
        <ch-layout :table-shadow="false" page>
          <template v-slot:default>
            <ch-table :render-option="tableOption" :data="tableList"
                      :loading="isTableLoading"
                      size="mini" mixHerght="100px">
            </ch-table>
          </template>
          <template v-slot:page>
            <ch-page
                ref="page" type="new" :size="10"
                :getListFunction="getTableList"
            ></ch-page>
          </template>
        </ch-layout>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import dayjs from "dayjs";

export default {
  props: {
    getListFunction: {
      type: Function
    }
  },
  data(){
    return {
      uboId: null,
      tableList: [],
      isOpenModal: false,
      isTableLoading: false,
      typeObj: {
        0:'操作人数',
        1:'社区数',
        2:'基础套餐',
        3:'短信',
      },
      url:null, // 请求url
    }
  },
  computed: {
    tableOption: function () {
      return [
        {column: 'type', prop: '', type: 'selection', width: '25px'},
        {column: 'type', label: '序号', prop: '', type: 'index', width: '50'},
        {column: 'text', label: '项目名称', prop: 'name', showOverflowTooltip: true },
        {column: 'text', label: '服务类型', prop: '_type', showOverflowTooltip: true },
        {column: 'text', label: '天数', prop: 'day', showOverflowTooltip: true },
        {column: 'cash', label: '单价', prop: 'unitPrice', showOverflowTooltip: true},
        {column: 'text', label: '数量', prop: 'number', showOverflowTooltip: true},
        {column: 'cash', label: '金额', prop: 'price', showOverflowTooltip: true},
        {column: 'text', label: '服务开始日期', prop: 'serverStartTime', showOverflowTooltip: true,width: 160},
        {column: 'text', label: '服务到期日期', prop: 'serverEndTime', showOverflowTooltip: true,width: 160},
      ]
    },
  },

  methods: {

    // 打开查看
    openWatch(row,url) {
      this.id = row.id
      this.url = url
      this.isOpenModal = true
      this.getTableList()
    },

    // 列表数据
    getTableList() {
      this.isTableLoading = true
      this.$nextTick(()=> {
        this.$refs?.page
            ?.getServerList(this.url+this.id, {}).then(res => {
          let rows = res.rows;
          rows.forEach((item) => {
            if (item.type === 0 || item.type === 1){
              item.name=item.name+"("+item.quantity+")";
            }
            item._type = this.typeObj[item.type] || '一'
            item.serverStartTime = item.serverStartTime && dayjs(item.serverStartTime).format('YYYY-MM-DD HH:mm:ss')
            item.serverEndTime = item.serverEndTime && dayjs(item.serverEndTime).format('YYYY-MM-DD HH:mm:ss')
          })
          this.tableList = rows;
          this.isTableLoading = false
        }).catch(() => {
          this.isTableLoading = false
        })
      })
    },

    // 关闭前清除数据
    closeModal() {
      this.uboId = null
      this.isOpenModal = false
      this.isTableLoading = false
    },
  }
}
</script>

<style scoped lang="scss">
.dialog-body-wrapper {
  width: 1200px;
  min-height: 300px;
  max-height: 500px;
}
</style>