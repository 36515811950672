<template>
  <div>
    <!-- 服务明细 -->
    <ch-layout page title="服务明细">
      <template v-slot:default>
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading" size="mini" height="100%">
          <template v-slot:handle="scope">
            <ch-button type="link"
                       @click="openWatch(scope.row)"
            >明细</ch-button>
          </template>
        </ch-table>
      </template>

      <template v-slot:page>
        <ch-page
          type="new" ref="page"
          :getListFunction="getTableList"
        ></ch-page>
      </template>
    </ch-layout>
    <modal ref="modal" :get-list-function="getTableList"></modal>
  </div>
</template>
<script>
import Modal from '../baseOrder/modal'
import { getTreeSelect } from "@/utils/utils";
import dayjs from "dayjs";

export default {
  components: {Modal},
  data() {
    return {
      isTableLoading: false,
      tableList: [],
      typeObj: {
        1:'基础套餐',
      },
      treeSelect: {
        orderDetails: false, // 服务明细明细
      },
    }
  },
  created() {
    // 获取操作显示权限
    getTreeSelect('contractManagement').then((res) => {
      this.treeSelect = res
    })
  },

  computed: {
    tableOption: function () {
      return [
        {column: 'type', prop: '', type: 'selection', width: '25px'},
        {column: 'type', label: '序号', prop: '', type: 'index', width: '50'},
        {column: 'text', label: '项目', prop: 'name', showOverflowTooltip: true},
        {column: 'text', label: '服务类型', prop: '_type', showOverflowTooltip: true},
        {column: 'text', label: '到期日期', prop: 'serverEndTime', showOverflowTooltip: true},
        {column: 'text', label: '状态', prop: '_status', showOverflowTooltip: true},
        {column: 'slot', label: '操作', slotName: 'handle', width: '60px', fixed: 'right'}
      ]
    },
  },
  mounted() {
    this.getTableList()
  },
  methods: {
    // 列表数据
    getTableList() {
      this.isTableLoading = true
      this.$refs.page?.getServerList('/hm/serverorders/details').then(res => {
        let rows = res.rows;
        rows.forEach((item) => {
          item._status = item.status === 0? '生效中':item.status === 1? '已过期':'一'
          item._type = this.typeObj[item.type] || '一'
          item.serverEndTime = item.serverEndTime && dayjs(item.serverEndTime).format('YYYY-MM-DD HH:mm:ss')
        })
        this.tableList = rows;
        this.isTableLoading = false
      }).catch(() => {
        this.isTableLoading = false
      })
    },
    // 打开明细
    openWatch(row) {
      this.$refs.modal?.openWatch(row,'/hm/serverorders/detailList/')
    },

  }

}
</script>

<style lang="scss">

</style>
